import React, { useEffect, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledAboutSection = styled.section`
  max-width: 900px;

  .inner {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;
const StyledText = styled.div`
  .skills {
    margin-top: 50px;
  }
  .skills-content {
    display: flex;
    gap: 3vw;
    flex-wrap: wrap;
  }

  ul.skills-list {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;
const StyledPic = styled.div`
  position: relative;
  max-width: 300px;

  @media (max-width: 768px) {
    margin: 50px auto 0;
    width: 70%;
  }

  .wrapper {
    ${({ theme }) => theme.mixins.boxShadow};
    display: block;
    position: relative;
    width: 100%;
    border-radius: 10px;
    /* border-radius: var(--border-radius); */
    background-color: var(--green);

    &:hover,
    &:focus {
      outline: 0;

      &:after {
        top: 15px;
        left: 15px;
      }

      .img {
        filter: none;
        mix-blend-mode: normal;
        border-radius: 40px;
      }
    }

    .img {
      /* border-radius: 30px; */
      position: relative;
      border-radius: var(--border-radius);
      mix-blend-mode: multiply;
      filter: grayscale(100%) contrast(1);
      transition: var(--transition);
    }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
      transition: var(--transition);
    }

    &:before {
      top: 0;
      left: 0;
      background-color: var(--navy);
      mix-blend-mode: screen;
    }

    &:after {
      border: 2px solid var(--green);
      top: 20px;
      left: 20px;
      z-index: -1;
    }
  }
`;

const About = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  const skills = {
    frameworks: [
      { name: 'React', link: 'https://react.dev/' },
      { name: 'Next.js', link: 'https://nextjs.org/' },
      { name: 'Exprerss.js', link: 'https://nextjs.org/' },
      { name: 'Node.js', link: 'https://nodejs.org/en' },
      { name: 'Flask', link: 'https://flask.palletsprojects.com/en/3.0.x/' },
      { name: 'Django', link: 'https://www.djangoproject.com/' },
    ],
    languages: [
      { name: 'Python', link: 'https://www.python.org/' },
      { name: 'JavaScript', link: 'https://developer.mozilla.org/fr/docs/Web/JavaScript' },
      { name: 'TypeScript', link: 'https://www.typescriptlang.org/' },
      { name: 'SQL', link: 'https://sql.sh/' },
      ,
    ],
    datas: [
      { name: 'Spark', link: 'https://spark.apache.org/' },
      { name: 'Apache Airflow', link: 'https://airflow.apache.org/' },
      { name: 'Terraform', link: 'https://airflow.apache.org/' },
      { name: 'DBT', link: 'https://www.getdbt.com/' },
      { name: 'Pandas', link: 'https://pandas.pydata.org/' },
      {
        name: 'BigQuery',
        link: 'https://cloud.google.com/bigquery?utm_source=google&utm_medium=cpc&utm_campaign=emea-fr-all-en-dr-bkws-all-all-trial-e-gcp-1707574&utm_content=text-ad-none-any-dev_c-cre_574683463340-adgp_Hybrid+%7C+BKWS+-+EXA+%7C+Txt+-+Data+Analytics+-+BigQuery+-+v1-kwid_43700072692462156-kwd-47616965283-userloc_9056136&utm_term=kw_bigquery-net_g-plac_&&gad_source=1&gclid=CjwKCAjwyo60BhBiEiwAHmVLJdHQCm4EgWv7v_WTyWPkNAn0P_QXGeaDKfSPYn25oyXKnNE2-R93zhoCjBQQAvD_BwE&gclsrc=aw.ds&hl=en',
      },
    ],
    clouds: [{ name: 'GCP', link: 'https://cloud.google.com/docs' }],
    tools: [
      { name: 'Docker', link: 'https://www.docker.com/' },
      { name: 'Kubernetes', link: 'https://kubernetes.io/' },
      { name: 'Git', link: 'https://git-scm.com/' },
      { name: 'GitLab', link: 'https://about.gitlab.com/' },
      { name: 'GitHub', link: 'https://github.com/' },
      { name: 'VsCode', link: 'https://code.visualstudio.com/' },
    ],
  };

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      <h2 className="numbered-heading">About Me</h2>

      <div className="inner">
        <StyledText>
          <div>
            <p> Hi!</p>
            <p>
              Hello! I'm Malek, a passionate Data Engineer and Full Stack Developer. I thrive on
              finding innovative ways to leverage data to solve complex problems.
            </p>
            <p>
              Over the years, I've built numerous websites and applications using a variety of tools
              and frameworks, including React, Django, Flask, Next.js, and Express.js. In the data
              engineering realm, I work extensively with technologies such as Apache Spark, DBT, and
              Terraform, and I am proficient with cloud platforms like Google Cloud.
            </p>
            <p>
              I take pride in creating solutions that work seamlessly and finding clever methods to
              make data meaningful and actionable. Beyond coding, I am always eager to learn about
              the latest advancements in technology and enjoy exploring new places and cultures.
              <br />
            </p>

            <p style={{ color: 'lightseagreen' }}>Let's work together to make cool things!</p>
          </div>
        </StyledText>

        <StyledPic>
          <div className="wrapper">
            <StaticImage
              className="img"
              src="../../../src/images/cvPhoto.png"
              width={400}
              quality={100}
              formats={['AUTO', 'WEBP', 'AVIF', 'PNG']}
              alt="Headshot"
            />
          </div>
        </StyledPic>
      </div>
      <StyledText>
        <div className="skills">
          <h3 className="skills-title">Skills</h3>
          <div className="skills-content">
            <ul className="skills-list">
              <h6>Language</h6>
              {skills &&
                skills.languages.map((language, i) => (
                  <li key={i}>
                    <a href={language.link} target="_blank">
                      {language.name}
                    </a>
                  </li>
                ))}
            </ul>
            <ul className="skills-list">
              <h6>Framework</h6>

              {skills &&
                skills.frameworks.map((framework, i) => (
                  <li key={i}>
                    <a href={framework.link} target="_blank">
                      {' '}
                      {framework.name}{' '}
                    </a>
                  </li>
                ))}
            </ul>

            <ul className="skills-list">
              <h6>Data</h6>

              {skills &&
                skills.datas.map((data, i) => (
                  <li key={i}>
                    <a href={data.link} target="_blank">
                      {data.name}
                    </a>
                  </li>
                ))}
            </ul>

            <ul className="skills-list">
              <h6>TOOLS</h6>

              {skills &&
                skills.tools.map((tool, i) => (
                  <li key={i}>
                    <a href={tool.link} target="_blank">
                      {' '}
                      {tool.name}{' '}
                    </a>
                  </li>
                ))}
            </ul>

            <ul className="skills-list">
              <h6>Cloud</h6>

              {skills &&
                skills.clouds.map((cloud, i) => (
                  <li key={i}>
                    <a href={cloud.link} target="_blank">
                      {' '}
                      {cloud.name}{' '}
                    </a>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </StyledText>
    </StyledAboutSection>
  );
};

export default About;
