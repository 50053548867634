import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(25.000000, 35.000000)">
        <path
          d="M 27.792 25.488 L 24.552 25.488 L 24.552 0.288 L 29.34 0.288 L 36.324 16.236 L 43.2 0.288 L 48.096 0.288 L 48.096 25.488 L 44.676 25.488 L 44.676 4.644 L 37.944 19.8 L 34.56 19.8 L 27.792 4.644 L 27.792 25.488 Z M 0 25.416 L 0 0.36 Q 1.692 0.18 3.312 0.09 A 54.471 54.471 0 0 1 4.819 0.029 Q 5.589 0.007 6.442 0.002 A 92.38 92.38 0 0 1 7.02 0 A 17.499 17.499 0 0 1 9.664 0.19 Q 11.282 0.437 12.618 1.008 A 11.152 11.152 0 0 1 15.242 2.563 A 9.8 9.8 0 0 1 16.506 3.78 Q 18.036 5.544 18.774 7.866 A 15.932 15.932 0 0 1 19.484 11.825 A 18.542 18.542 0 0 1 19.512 12.852 Q 19.512 15.48 18.756 17.82 Q 18 20.16 16.434 21.942 A 10.429 10.429 0 0 1 13.462 24.264 A 12.692 12.692 0 0 1 12.438 24.75 Q 10.179 25.704 7.095 25.771 A 21.669 21.669 0 0 1 6.624 25.776 A 67.312 67.312 0 0 1 5.158 25.761 Q 3.998 25.736 3.042 25.668 Q 1.512 25.56 0 25.416 Z M 3.42 3.132 L 3.42 22.608 Q 4.176 22.716 5.004 22.752 A 38.615 38.615 0 0 0 6.287 22.786 A 43.66 43.66 0 0 0 6.732 22.788 Q 9.315 22.788 11.143 21.987 A 7.211 7.211 0 0 0 12.024 21.528 Q 14.076 20.268 15.048 18.018 A 11.625 11.625 0 0 0 15.86 15.111 A 15.301 15.301 0 0 0 16.02 12.852 Q 16.02 10.26 15.048 7.992 Q 14.076 5.724 12.06 4.338 A 7.522 7.522 0 0 0 9.521 3.237 Q 8.499 2.992 7.307 2.958 A 13.683 13.683 0 0 0 6.912 2.952 Q 5.868 2.952 5.004 3.006 Q 4.14 3.06 3.42 3.132 Z"
          fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
